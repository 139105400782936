import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { pickLightOrDarkColorKind } from './utils';
import { IntroStyles, ImageWrapper } from './Intro.style';
import { Heading } from '../../components';

/**
 * Blog: Intro composition
 */
const Intro = ({ postKind, title, inlineSubtitle, image, colorKind }) => {
  return (
    <IntroStyles>
      {postKind !== 'quote' && (
        <Heading element="h1" colorKind={pickLightOrDarkColorKind(colorKind)}>
          {title} {inlineSubtitle && <span style={{ fontWeight: 400 }}>{inlineSubtitle}</span>}
        </Heading>
      )}
      {image && (
        <ImageWrapper>
          <Img fluid={image.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} alt={title} />
        </ImageWrapper>
      )}
    </IntroStyles>
  );
};

Intro.propTypes = {
  postKind: PropTypes.oneOf(['quote', 'default']),
  title: PropTypes.string.isRequired,
  inlineSubtitle: PropTypes.string,
  image: PropTypes.any,
  colorKind: PropTypes.oneOf(['light', 'dark', 'text']),
};

Intro.defaultProps = {
  postKind: 'default',
  inlineSubtitle: null,
  image: null,
  colorKind: 'default',
};

export default Intro;
