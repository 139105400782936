import React from 'react';
import PropTypes from 'prop-types';
import { Twitter, Rss } from '../../components';
import { List, Item, SidebarLink } from './Sidebar.style';
import { pickLightOrDarkColorKind } from './utils';

const pickTwitterIconColor = colorKind => (colorKind === 'text' ? '#4cc4fc' : 'currentColor');
const pickRssIconColor = colorKind => (colorKind === 'text' ? '#ffa500' : 'currentColor');

/**
 * Blog: Navigation composition
 * TODO: Introduce HeyLikey :-)
 */
const Sidebar = ({ title, inlineSubtitle, url, colorKind }) => {
  const color = pickLightOrDarkColorKind(colorKind);
  const shareTitle = `${inlineSubtitle ? `${title} ${inlineSubtitle}` : `${title}`}`;

  return (
    <aside>
      <List>
        <Item>
          <SidebarLink
            colorKind={color}
            linkKind="no-underline"
            to={`https://twitter.com/intent/tweet?text=${shareTitle}%20by%20Danijel%20Grabež&url=https://www.danijelgrabez.com${url}`}
          >
            <Twitter size={16} fill={pickTwitterIconColor(colorKind)} />
            Tweet
          </SidebarLink>
        </Item>
        <Item>
          <SidebarLink to="/rss.xml" colorKind={color} linkKind="no-underline">
            <Rss size={16} fill={pickRssIconColor(colorKind)} />
            RSS Feed
          </SidebarLink>
        </Item>
      </List>
    </aside>
  );
};

Sidebar.propTypes = {
  title: PropTypes.string.isRequired,
  inlineSubtitle: PropTypes.string,
  url: PropTypes.string.isRequired,
  colorKind: PropTypes.oneOf(['text', 'light', 'dark']).isRequired,
};

Sidebar.defaultProps = {
  inlineSubtitle: '',
};

export default Sidebar;
