import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { ThemeProvider } from 'emotion-theming';
import { Global } from '@emotion/core';
import theme from '../utils/theme';
import { scaffoldingStyles } from '../utils/scaffolding';
import { SEO, TopNavigation, Section, Footer, Link, TextAccent } from '../components';
import { Wrapper, BlogContainer, Article, FooterWrapper, BlogPostFooter } from '../compositions/Blog/Blog.style';
import Navigation from '../compositions/Blog/Navigation';
import Intro from '../compositions/Blog/Intro';
import Sidebar from '../compositions/Blog/Sidebar';
import { pickLightOrDarkColorKind } from '../compositions/Blog/utils';

/**
 * Blog post template
 */
const BlogPost = props => {
  const post = props.data.mdx;
  const { previous, next } = props.pageContext;
  const {
    title,
    date,
    inlineSubtitle,
    postKind,
    withIntroImage,
    blogThemeConfig,
    image,
    tags,
    metaDescription,
    blogThemeConfig: { color },
  } = post.frontmatter;

  return (
    <ThemeProvider theme={theme}>
      <Global styles={scaffoldingStyles} />
      <SEO
        title={`${title} ${inlineSubtitle || ''} | Danijel Grabež`}
        description={metaDescription}
        metaImage={image}
        pagePath={props.path}
      />
      <Wrapper blogThemeConfig={blogThemeConfig}>
        <BlogContainer>
          <TopNavigation to="/blog" title="Writings" colorKind={pickLightOrDarkColorKind(color)} />
          <Intro
            postKind={postKind}
            title={title}
            inlineSubtitle={inlineSubtitle}
            image={withIntroImage === null || withIntroImage ? image : undefined}
            colorKind={color}
          />
          <Section
            sidebar={<Sidebar title={title} inlineSubtitle={inlineSubtitle} url={props.path} colorKind={color} />}
          >
            <Article blogThemeConfig={blogThemeConfig}>
              <MDXRenderer>{post.body}</MDXRenderer>
              <BlogPostFooter>
                <TextAccent element="time" colorKind={pickLightOrDarkColorKind(color)}>
                  {date}
                </TextAccent>
                <div>
                  {tags.map((item, index) => (
                    <Fragment key={item}>
                      <Link to={`/blog/${item}`} key={item} colorKind={pickLightOrDarkColorKind(color)}>
                        #{item}
                      </Link>
                      {tags.length !== index + 1 ? ', ' : ' '}
                    </Fragment>
                  ))}
                </div>
                <Navigation previous={previous} next={next} colorKind={color} />
              </BlogPostFooter>
            </Article>
          </Section>
          <FooterWrapper>
            <Footer showBio={false} colorKind={color} />
          </FooterWrapper>
        </BlogContainer>
      </Wrapper>
    </ThemeProvider>
  );
};

BlogPost.propTypes = {
  path: PropTypes.string.isRequired,
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      body: PropTypes.any,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
        date: PropTypes.string,
        inlineSubtitle: PropTypes.string,
        postKind: PropTypes.oneOf(['default', 'quote']),
        withIntroImage: PropTypes.any,
        blogThemeConfig: PropTypes.shape({
          color: PropTypes.string,
          background: PropTypes.string,
        }),
        image: PropTypes.any,
        tags: PropTypes.array,
        metaDescription: PropTypes.string,
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    previous: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }),
    next: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        inlineSubtitle
        date(formatString: "MMMM DD, YYYY")
        postKind
        withIntroImage
        image {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tags
        metaDescription
        blogThemeConfig {
          color
          background
        }
      }
    }
  }
`;
