import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from '../../components';

const listStyles = css`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
`;

const itemStyles = css`
  &:last-of-type {
    margin-left: 15px;
  }
`;

const previousLinkStyles = css`
  text-decoration: none;
  display: inline-block;
  &:hover {
    transform: translateX(-2px);
  }
`;

const nextLinkStyles = css`
  text-decoration: none;
  display: inline-block;
  &:hover {
    transform: translateX(2px);
  }
`;

/**
 * Component style declarations
 */
export const List = styled('ul')(listStyles);
export const Item = styled('li')(itemStyles);
export const PreviousLink = styled(Link)(previousLinkStyles);
export const NextLink = styled(Link)(nextLinkStyles);
