import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from '../../components';

const listStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0 0 30px;
  ${breakpoint.fromTablet} {
    flex-direction: column;
    margin: 0;
    position: sticky;
    top: 50px;
    z-index: 5;
  }
`;

const itemStyles = ({
  theme: {
    grid: { breakpoint },
  },
}) => css`
  margin-bottom: 10px;

  ${breakpoint.phones} {
    margin: 0;
    &:not(:last-of-type) {
      margin-right: 30px;
    }
  }
`;

const sidebarLinkStyles = css`
  font-weight: 700;
  svg {
    margin-right: 7px;
  }

  &:active {
    svg {
      opacity: 0.9;
    }
  }
`;

/**
 * Component style declarations
 */
export const List = styled('ul')(listStyles);
export const Item = styled('li')(itemStyles);
export const SidebarLink = styled(Link)(sidebarLinkStyles);
