import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { Tooltip } from '../../components';
import useKeyboardEvent from '../../utils/useKeyboardEvent';
import { List, Item, PreviousLink, NextLink } from './Navigation.style';
import { pickLightOrDarkColorKind } from './utils';

/**
 * Blog: Navigation composition
 */
const Navigation = ({ previous, next, colorKind }) => {
  const color = pickLightOrDarkColorKind(colorKind);

  useKeyboardEvent('ArrowLeft', true, () => (previous ? navigate(`/blog${previous.fields.slug}`) : {}));
  useKeyboardEvent('ArrowRight', true, () => (next ? navigate(`/blog${next.fields.slug}`) : {}));

  return (
    <List>
      {previous && (
        <Item>
          <PreviousLink colorKind={color} to={`/blog${previous.fields.slug}`} rel="prev">
            <Tooltip content="Use ← key to navigate">←</Tooltip>
          </PreviousLink>
        </Item>
      )}
      {next && (
        <Item>
          <NextLink colorKind={color} to={`/blog${next.fields.slug}`} rel="next">
            <Tooltip content="Use → key to navigate">→</Tooltip>
          </NextLink>
        </Item>
      )}
    </List>
  );
};

Navigation.propTypes = {
  colorKind: PropTypes.oneOf(['text', 'light', 'dark']),
  previous: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  }),
  next: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  }),
};

Navigation.defaultProps = {
  colorKind: 'dark',
  previous: {
    fields: {},
  },
  next: {
    fields: {},
  },
};

export default Navigation;
