import { useEffect } from 'react';

/**
 * useKeyboardEvent
 * @param {string} key - Keyboard key which will trigger the effect if condition is met.
 * @param {boolean} condition - Condition under which event listener should be attached (e.g. Modal is visible).
 * @param {() => void} callback - Callback function which should be run when event keyboard event is registered.
 */
const useKeyboardEvent = (key, condition, callback) => {
  useEffect(() => {
    if (condition) {
      const handler = function(event) {
        if (event.key === key) {
          callback();
        }
      };

      window.addEventListener('keydown', handler);

      return () => {
        window.removeEventListener('keydown', handler);
      };
    }
  }, [condition, callback, key]);
};

export default useKeyboardEvent;
